import React, {useContext, useEffect, useState} from 'react';
import {Button, Card} from 'semantic-ui-react';
import {RootStoreContext} from "../../../stores/rootStore";
import LineManagerForm from "../../forms/LineManagerForm";
import ProfileForm from "../../forms/ProfileForm";
import {UserStatus} from "../../../stores/userStore";
import {observer} from "mobx-react-lite";


const ProfilePanel = () => {
  const rootStore = useContext(RootStoreContext);
  const {openModal} = rootStore.modalStore;
  const {assessmentStatus, user} = rootStore.userStore;

  const [assessmentStatusText, setAssessmentStatusText] = useState('');
  
  useEffect(() => {
    switch (assessmentStatus) {
      case UserStatus.Started:
        setAssessmentStatusText('Started');
        break;
      case UserStatus.ReadyToSubmit:
        setAssessmentStatusText('Ready to submit');
        break;
      case UserStatus.PendingManagerReview:
        setAssessmentStatusText('Pending manager review');
        break;
      case UserStatus.Completed:
        setAssessmentStatusText('Complete');
        break;
    }
  },[assessmentStatus, assessmentStatusText, setAssessmentStatusText])
  
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>{user.firstName} {user.lastName}</Card.Header>
        <Card.Meta>{user.role}</Card.Meta>
        <Card.Description>Last reviewed: 10/05/2020</Card.Description>
        <Card.Description>Current Status: {assessmentStatusText}</Card.Description>
        <Button
          onClick={() => openModal(<ProfileForm />)}
          secondary size='mini' floated='right'>Edit profile</Button>
      </Card.Content>
      <Card.Content extra>
        Line manager: {user.lineManager.name}
        <Button
          onClick={() => openModal(<LineManagerForm />)}  
          secondary size='mini' floated='right'>Edit line manager</Button>
      </Card.Content>
    </Card>
  )
}

export default observer(ProfilePanel);
