import React, { Fragment } from 'react';
import {Header, Icon} from "semantic-ui-react";
import {IPanelType} from "../../dashboard/panels/CompetencyPanel";

const ManagerCompetencyAreas: React.FC<{panelType: number}>  = ({panelType}) => {

  // Eventually this will be pulled from the database
  const panels: IPanelType[] = [
    {icon: 'cogs', title:'Brand & Consumer Strategy', description: 'Developing winning Marketing, Brand and Customer strategies delivered through annual plans which enable the business to meet its financial goals - both short and long'},
    {icon: 'lightbulb', title:'Insight', description: 'Generating and applying insights developed from a deep consumer, shopper, retailer understanding and operator, which lead to differentiated business opportunities across the marketing mix'},
    {icon: 'chat', title:'Winning Innovation', description: 'Driving profitable growth through product innovation, renovation & life cycle management'},
    {icon: 'pencil', title:'Media Planning & Execution', description: 'Planning and developing integrated creative communications to build brand advantage'},
    {icon: 'map signs', title:'Customer Marketing', description: 'Developing and executing activity that brings the brand positioning & creative big idea to life across all touch points to strengthen brand equity and deliver top line growth'}
  ]

  return (
    <Fragment>
      <Header as='h2'>
        <Icon name={panels[panelType].icon} />
        <Header.Content>
          <Header.Subheader>{panels[panelType].title}</Header.Subheader>
        </Header.Content>
      </Header>
      <p>{panels[panelType].description}</p>
    </Fragment>
  )
}

export default ManagerCompetencyAreas;
