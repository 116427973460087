import React, {Fragment} from 'react';
import {Grid, Segment} from "semantic-ui-react";
import ManagerAssessmentForReview from "./panels/ManagerAssessmentForReview";
import ManagerAssessmentComplete from "./panels/ManagerAssessmentComplete";
import WelcomeManagerPanel from "./panels/WelcomeManagerPanel";
import ManagerCompetencyAreas from "./panels/ManagerCompetencyAreas";


const ManagerDashboard = () => {

  const ManagerDashboardAreasList = () => {
    let areaList = [];
    for(let i = 0; i < 5; i++) {
      areaList.push(<Grid.Column key={i}><ManagerCompetencyAreas panelType={i} /></Grid.Column>);
    }
    return areaList;
  }
  
  return (
    <Fragment>
      <WelcomeManagerPanel />
      <h4 className="ui dividing header">Areas</h4>
      <Segment>
        <Grid stackable divided columns={5}>
          {ManagerDashboardAreasList()}
        </Grid>
      </Segment>
      <h4 className="ui dividing header">Assessments for review</h4>
      <Segment>
        <Segment vertical>
          <ManagerAssessmentForReview />
        </Segment>
        <Segment vertical>
          <ManagerAssessmentForReview />
        </Segment>
      </Segment>
      <h4 className="ui dividing header">Assessments completed</h4>
      <Segment>
        <Segment vertical>
          <ManagerAssessmentComplete />
        </Segment>
        <Segment vertical>
          <ManagerAssessmentComplete />
        </Segment>
      </Segment>

    </Fragment>
  )
}

export default ManagerDashboard;
