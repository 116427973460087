import React, {Fragment, useContext} from 'react';
import {Button, Grid, Icon} from "semantic-ui-react";
import RateCompetenciesModal from "../../common/RateCompetenciesModal";
import {RootStoreContext} from "../../../stores/rootStore";
import ViewCompetenciesModal from "../../common/ViewCompetenciesModal";
import {history} from "../../../../index";

const ManagerAssessmentComplete = () => {
  const rootStore = useContext(RootStoreContext);
  const {openModal} = rootStore.modalStore;

  // Eventually this will be pulled from the database
  const panels: any[] = [
    'cogs',
    'lightbulb',
    'chat',
    'pencil',
    'map signs'
  ]

  return (
    <Fragment>
      <Grid stretched>
        <Grid.Column width={1}>
          <Icon name='warning circle' size='big' />
        </Grid.Column>
        <Grid.Column width={2}>
          Jim Bowen<br />
          Chief Technician<br />
          17 June 2020
        </Grid.Column>
        <Grid.Column width={13}>
          <Grid columns='equal'>
            <Grid.Column textAlign='center'>
              <Icon name={panels[0]} size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => openModal(<ViewCompetenciesModal />)}
                      basic
                      content='View'
                      size='mini'
              />
            </Grid.Column>

            <Grid.Column textAlign='center'>
              <Icon name={panels[1]} size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => openModal(<ViewCompetenciesModal />)}
                      basic
                      content='View'
                      size='mini'
              />
            </Grid.Column>

            <Grid.Column textAlign='center'>
              <Icon name={panels[2]} size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => openModal(<ViewCompetenciesModal />)}
                      basic
                      content='View'
                      size='mini'
              />
            </Grid.Column>

            <Grid.Column textAlign='center'>
              <Icon name={panels[3]} size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => openModal(<ViewCompetenciesModal />)}
                      basic
                      content='View'
                      size='mini'
              />
            </Grid.Column>

            <Grid.Column textAlign='center'>
              <Icon name={panels[4]} size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => openModal(<ViewCompetenciesModal />)}
                      basic
                      content='View'
                      size='mini'
              />
            </Grid.Column>

            <Grid.Column textAlign='center'>
              <Icon name='chart bar' size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => history.push('/results')}
                      positive
                      content='View'
                      size='mini'/>
            </Grid.Column>

          </Grid>

        </Grid.Column>
      </Grid>
    </Fragment>
  )
}

export default ManagerAssessmentComplete;
