import React from 'react';
import {Container} from "semantic-ui-react";

const FooterBar = () => {
  return (
    <div className='footer'>
      <Container>
        <p>&copy; 2020 Multiply</p>
      </Container>
    </div>
  )
}

export default FooterBar;
