import React from 'react';
import {Card, Header} from "semantic-ui-react";

const WelcomeManagerPanel = () => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header><Header as='h1' size='medium'>Welcome to Marketing Skills Assessment Tool Manager's Dashboard</Header></Card.Header>
        <Card.Description>
          This easy to use tool lets you rate the current competency levels for your team against each of the 25 Kraft Heinz competencies.
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default WelcomeManagerPanel;
