import React, {Fragment} from 'react';
import {Button, Container, Grid, Header, Segment} from "semantic-ui-react";
import ResultsRadialChart from "./charts/ResultsRadialChart";
import ResultsLineChart from "./charts/ResultsLineChart";


const ResultsDashboard = () => {
  return (
    <Fragment>
      <Segment>
        <Grid columns='equal' divided stackable>
          <Grid.Column>
            <Header as='h1' icon='warning circle' content='Your results' />
            <p>Your mean scores indicate that you are currently rating:</p>
              <ul>
                <li>Ahead of the standard for [Brand & Customer Strategy / Insight /Winning Innovation / Media Planning & Execution / Customer Marketing]</li>
                <li>In line with the standard for [Brand & Customer Strategy / Insight /Winning Innovation / Media Planning & Execution / Customer Marketing]</li>
                <li>Behind the standard for [Brand & Customer Strategy / Insight /Winning Innovation / Media Planning & Execution / Customer Marketing</li>
              </ul>

            <Header as='h2' icon='newspaper' content='Action plan' />
            <p>
              Click here to view, save or print your individual action plan.
            </p>
            <Container>
              <Button icon='file pdf' content='Download plan' color='green' />
              <Button icon='linkify' content='Online learning' color='green' />
            </Container>
          </Grid.Column>
          <Grid.Column>
            <ResultsRadialChart />
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment>
        <ResultsLineChart />
      </Segment>
    </Fragment>
  )
}

export default ResultsDashboard;
