import React, {useContext} from 'react';
import {Icon, List, Popup, Rating, Table} from "semantic-ui-react";
import {RootStoreContext} from "../../stores/rootStore";

const SingleCompetencyRating: React.FC<{competencyName: string}> = ({competencyName}) => {
  const rootStore = useContext(RootStoreContext);
  const {setShowExtraCompetencyInfo} = rootStore.commonStore;
  
  return (
    <Table.Row>
      <Table.Cell>
        {competencyName}
        <Popup trigger={<Icon name='info circle' onClick={() => setShowExtraCompetencyInfo(true)}/>}>
          <Popup.Content>
            Developing a clear statement of why the brand exists, set in the context of people's lives. Should be long-lasting, ownable, rooted in brand history, culturally powered, have voltage and be polarising.
          </Popup.Content>
        </Popup>
      </Table.Cell>
      <Table.Cell><Rating icon='star' defaultRating={0} maxRating={4} size='huge' clearable /></Table.Cell>
    </Table.Row>
  )
}

export default SingleCompetencyRating;
