import React from 'react';
import {Card, Header} from "semantic-ui-react";

const WelcomePanel = () => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header><Header as='h1' size='medium'>Welcome to Marketing Skills Assessment Tool</Header></Card.Header>
        <Card.Description>
          This easy to use tool lets you and your line manager rate and view your current competency levels for each of the 25 Kraft Heinz competencies.
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default WelcomePanel;
