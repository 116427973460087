import React, {Fragment, useContext} from 'react';
import {Grid, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import SubmitAssessmentPanel from "./assessmentStatus/SubmitAssessmentPanel";
import StartedAssessmentPanel from "./assessmentStatus/StartedAssessmentPanel";
import WelcomePanel from "./panels/WelcomePanel";
import CompetencyPanel, {CompetencyStatus} from "./panels/CompetencyPanel";
import ProfilePanel from "./panels/ProfilePanel";
import {RootStoreContext} from "../../stores/rootStore";
import {UserStatus} from "../../stores/userStore";
import CompletedAssessmentPanel from "./assessmentStatus/CompletedAssessmentPanel";

const Dashboard = () => {
  const rootStore = useContext(RootStoreContext);
  const {assessmentStatus} = rootStore.userStore;
  
  return (
    <Fragment>
        <Grid stackable columns={2} divided>
          <Grid.Row stretched>
            <Grid.Column>
              <WelcomePanel />
            </Grid.Column>
            <Grid.Column>
              <ProfilePanel />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      <h4 className="ui dividing header">My Competency</h4>
      <Segment>
        <Grid stackable doubling columns={3} stretched celled='internally' verticalAlign='top'>
          <Grid.Row>
          <Grid.Column><CompetencyPanel panelType={0} status={CompetencyStatus.Start}/></Grid.Column>
          <Grid.Column><CompetencyPanel panelType={1} status={CompetencyStatus.Start}/></Grid.Column>
          <Grid.Column><CompetencyPanel panelType={2} status={CompetencyStatus.Update}/></Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column><CompetencyPanel panelType={3} status={CompetencyStatus.Pending}/></Grid.Column>
          <Grid.Column><CompetencyPanel panelType={4} status={CompetencyStatus.Complete}/></Grid.Column>
          <Grid.Column>
            {assessmentStatus === UserStatus.Started && <StartedAssessmentPanel />}
            {assessmentStatus === UserStatus.ReadyToSubmit && <SubmitAssessmentPanel />}
            {assessmentStatus === UserStatus.Completed && <CompletedAssessmentPanel />}
          </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Fragment>
  )
}

export default observer(Dashboard);