import {RootStore} from "./rootStore";
import {action, observable} from "mobx";


export default class CommonStore {
  rootStore: RootStore;
  
  constructor(rootStore:RootStore) {
    this.rootStore = rootStore;
  }
  
  @observable appLoaded = true;
  
  @action setAppLoaded = () => {
    this.appLoaded = true;
  }
  
  @observable showExtraCompetencyInfo = false;
  
  @action setShowExtraCompetencyInfo = (status: boolean) => {
    this.showExtraCompetencyInfo = status;
  }
}
