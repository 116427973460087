import React, {Fragment, useContext, useEffect} from 'react';
import '../../styles/styles.css';
import {Container} from 'semantic-ui-react'
import {observer} from "mobx-react-lite";
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom';
import NotFound from "./NotFound";
import {ToastContainer} from "react-toastify";
import LoadingComponent from "./LoadingComponent";
import {RootStoreContext} from "../stores/rootStore";
import ModalContainer from "./common/ModalContainer";
import ResultsDashboard from "./results/ResultsDashboard";
import NavBar from "./common/NavBar";
import Dashboard from "./dashboard/Dashboard";
import FooterBar from "./common/FooterBar";
import LandingPage from "./landing/LandingPage";
import ManagerDashboard from "./manager/ManagerDashboard";
import SupportDashboard from "./support/SupportDashboard";


const App: React.FC<RouteComponentProps> = ({location}) => {

  const rootStore = useContext(RootStoreContext);
  const {appLoaded} = rootStore.commonStore;

  if(!appLoaded) return <LoadingComponent content='Loading app...' />

  return (
    <Fragment>
      <ModalContainer />
      <ToastContainer position={"bottom-right"} />
      <Route exact path='/' component={LandingPage} />
      <Route path={'/(.+)'} render={() => (
        <Fragment>
          <div className='mainContent'>
            <NavBar />
            <Container style={{paddingTop: '7em'}}>
              <Switch>
                <Route exact path='/dashboard' component={Dashboard} />
                <Route exact path='/results' component={ResultsDashboard} />
                <Route exact path='/manager-dashboard' component={ManagerDashboard} />
                <Route exact path='/support' component={SupportDashboard} />
                <Route component={NotFound} />
              </Switch>
            </Container>
          </div>
          <FooterBar />
        </Fragment>
      )} />

    </Fragment>
  );
};

export default withRouter(observer(App));
