import React, {useCallback, useEffect, useRef, Fragment} from 'react';
import Chartjs from 'chart.js';

const radialChartConfig = {
  type: 'radar',
  data: {
    labels: ['Brand & Consumer', 'Insight', 'Customer Marketing', 'Media Planning & Execution', 'Innovation'],
    datasets: [
      {
        label: 'Role target',
        backgroundColor: "rgba(194, 126, 23, 0.2)",
        data: [10, 8, 6, 10, 14]
      },
      {
      label: 'Your averaged score',
      backgroundColor: "rgba(52, 207, 62, 0.4)",
      data: [12, 10, 4, 7, 8]
      }
    ]
  },
  options: {
    scale: {
      ticks: {
        suggestedMin: 0,
        suggestedMax: 20
      }
    }
  }
};

const ResultsRadialChart = () => {
  const chartRef = useCallback(node => {
    if (node !== null) {
      const newChartInstance = new Chartjs(node, radialChartConfig);
    }
  }, []);

  return (
    <Fragment>
      <canvas ref={chartRef} height='250' />
    </Fragment>
  );
};

export default ResultsRadialChart;