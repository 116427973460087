import {observer} from "mobx-react-lite";
import React, {useContext} from 'react';
import {Form as FinalForm, Field} from 'react-final-form';
import {values} from "mobx";
import {FORM_ERROR} from "final-form";
import {Button, Form, Header, Icon, Segment} from "semantic-ui-react";
import {RootStoreContext} from "../../stores/rootStore";
import TextInput from "../../components/formfields/TextInput";
import * as yup from "yup";
import {validate} from "../../components/utils/customValidators";

const validateSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .max(255)
      .required(),
    email: yup
      .string()
      .max(255)
      .required(),
  });

const LineManagerForm = () => {
  const rootStore = useContext(RootStoreContext);
  const {closeModal} = rootStore.modalStore;
  const {lineManager} = rootStore.userStore.user;

  const SaveLineManager = () => {
    alert("Save line manager");
    closeModal();
  }
  
  return (
    <Segment basic clearing>
      <Header textAlign='center' size='huge'>Update your line manager details</Header>
      <FinalForm 
        onSubmit={(value:any) => console.log(values)}
        validate={values => validate(values, validateSchema())}
        render={({handleSubmit, invalid, pristine,dirtySinceLastSubmit}) => (
        <Form onSubmit={handleSubmit} error>
          <Field name='name' component={TextInput} placeholder='Manager name' label='Manager name (** Note - this will autocomplete from the DB as you type)' />
          
          <p>{lineManager.name}</p>
          
          <Button positive size='large' floated='right'
                  disabled={(invalid && !dirtySinceLastSubmit)}
                  onClick={SaveLineManager} name='Save'>
            <Icon name='save' />
            Save
          </Button>

          <Button negative size='large' floated='right' name='Cancel'
                  onClick={closeModal}>
            <Icon name='cancel' />
            Cancel
          </Button>
        </Form>
        )}
      />
    </Segment>
  )
}

export default observer(LineManagerForm);
