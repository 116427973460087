import {observer} from "mobx-react-lite";
import React, {useContext, useEffect} from 'react';
import {Form as FinalForm, Field} from 'react-final-form';
import {values} from "mobx";
import {FORM_ERROR} from "final-form";
import {Button, Form, Grid, Header, Icon, Image, Segment} from "semantic-ui-react";
import {RootStoreContext} from "../../stores/rootStore";
import TextInput from "../../components/formfields/TextInput";
import * as yup from "yup";
import {validate} from "../../components/utils/customValidators";

const validateSchema = () =>
  yup.object().shape({
    role: yup
      .string()
      .max(255)
      .required()
  });


const ProfileForm = () => {
  const rootStore = useContext(RootStoreContext);
  const {closeModal} = rootStore.modalStore;
  const {user} = rootStore.userStore;

  const SaveProfile = () => {
    alert("Save profile");
    closeModal();
  }
  

  return (
    <Segment basic clearing>
      <Header size='huge' textAlign='center'>Update your profile</Header>

      <FinalForm 
        onSubmit={(value:any) => console.log(values)}
        initialValues={user}
        validate={values => validate(values, validateSchema())}
        render={({handleSubmit, invalid, pristine,dirtySinceLastSubmit}) => (
        <Form onSubmit={handleSubmit} error>
          <p style={{color: 'lightgrey'}}>These fields are locked and can only be updated by an administrator.</p>
          <Form.Group widths='equal'>
            <Field name='firstName' fluid label='First name' placeholder='First name' disabled component={TextInput} />
            <Field name='lastName' fluid label='Last name' placeholder='Last name' disabled component={TextInput} />
          </Form.Group>

          <Grid columns='equal'>
            <Grid.Column><Field label='Photo' name='photo' component={TextInput} type='file' /></Grid.Column>
            <Grid.Column><Image avatar size='tiny' spaced='right' src={'/assets/' + user.image} /></Grid.Column>
          </Grid>
          
          <Field label='Role' name='role' component={TextInput} placeholder='Role' />
          
          
          <Button positive size='large' floated='right'
                  disabled={(invalid && !dirtySinceLastSubmit)}
                  onClick={SaveProfile} name='Save'>
            <Icon name='save' />
            Save
          </Button>

          <Button negative size='large' floated='right' name='Cancel'
                  onClick={closeModal}>
            <Icon name='cancel' />
            Cancel
          </Button>
        </Form>
        )}
      />
    </Segment>
  )
}

export default observer(ProfileForm);
