import React from 'react';
import {Header, Segment} from "semantic-ui-react";
import SupportForm from "../forms/SupportForm";

const SupportDashboard = () => {
  return (
    <Segment clearing>
      <Header as='h1' size='medium'>Support</Header>
      <p>Please enter details of your query below. We will aim to respond to your enquiry within 1 working day.</p>
      <SupportForm />
    </Segment>
  )
}

export default SupportDashboard;
