import React, {Fragment, useContext, useEffect, useState} from 'react';
import {
  Button,
  Confirm,
  Container,
  Header,
  Icon,
  List,
  Message,
  Popup,
  Rating,
  Segment,
  Table
} from "semantic-ui-react";

import {observer} from "mobx-react-lite";
import SingleCompetencyRating from "./SingleCompetencyRating";
import {RootStoreContext} from "../../stores/rootStore";

const RateCompetenciesModal = () => {
  const rootStore = useContext(RootStoreContext);
  const {closeModal} = rootStore.modalStore;
  const {showExtraCompetencyInfo, setShowExtraCompetencyInfo} = rootStore.commonStore;
  const [showExtra, setShowExtra] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  
  useEffect(() => {
    setShowExtraCompetencyInfo(false);
  }, [setShowExtraCompetencyInfo]);

  const saveAndClose = () => {
    alert("Save competencies");
    closeModal();
  }

  return (
    <Fragment>
      <Confirm
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={closeModal}
        cancelButton='No, go back and edit'
        confirmButton="Yes, just close"
        content='Are you sure you want to close this window without saving your ratings?'
      />
      <Segment basic clearing>
        <Header size='huge' textAlign='center'>Rate your Brand & Consumer Strategy</Header>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={8} />
              <Table.HeaderCell>
                Skill level
                <Popup wide
                       mouseLeaveDelay={500}
                       trigger={
                         <Icon name='info circle' />
                       }>
                  <Popup.Content>
                    <Header>Skill levels</Header>
                    <List>
                      <List.Item><Rating icon='star' defaultRating={1} maxRating={4} size='small' disabled /> - Basic understanding </List.Item>
                      <List.Item><Rating icon='star' defaultRating={2} maxRating={4} size='small' disabled /> - Can Contribute Actively</List.Item>
                      <List.Item><Rating icon='star' defaultRating={3} maxRating={4} size='small' disabled /> - Can Lead the Process/Skill Area</List.Item>
                      <List.Item><Rating icon='star' defaultRating={4} maxRating={4} size='small' disabled /> - Recognised as an Expert/Coach in the Area</List.Item>
                    </List>
                  </Popup.Content>
                </Popup>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <SingleCompetencyRating competencyName='Brand Purpose' />
            <SingleCompetencyRating competencyName='Brand Assessment' />
            <SingleCompetencyRating competencyName='Brand Foundation' />
            <SingleCompetencyRating competencyName='Portfolio Strategy' />
            <SingleCompetencyRating competencyName='Marketing Planning' />
          </Table.Body>
        </Table>
        
        <Button positive size='large' floated='right' onClick={saveAndClose} name='Save'>
          <Icon name='save' />
          Save
        </Button>
        <Button negative size='large' floated='right' name='Cancel'
                onClick={() => setOpenConfirm(true)}>
          <Icon name='cancel' />
          Cancel
        </Button>
      </Segment>
      
    </Fragment>
  )
}

export default observer(RateCompetenciesModal);
