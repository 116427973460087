import React, {Fragment, useContext} from 'react';
import {Button, Grid, Icon} from "semantic-ui-react";
import RateCompetenciesModal from "../../common/RateCompetenciesModal";
import {RootStoreContext} from "../../../stores/rootStore";

const ManagerAssessmentForReview = () => {
  const rootStore = useContext(RootStoreContext);
  const {openModal} = rootStore.modalStore;

  // Eventually this will be pulled from the database
  const panels: any[] = [
    'cogs',
    'lightbulb',
    'chat',
    'pencil',
    'map signs'
  ]
  
  return (
    <Fragment>
      <Grid stretched>
        <Grid.Column width={1}>
          <Icon name='warning circle' size='big' />
        </Grid.Column>
        <Grid.Column width={2}>
          Jim Bowen<br />
          Chief Technician<br />
          17 June 2020
        </Grid.Column>
        <Grid.Column width={13}>
          <Grid columns='equal'>
            <Grid.Column textAlign='center'>
              <Icon name={panels[0]} size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => openModal(<RateCompetenciesModal />)}
                      primary
                      content='Start'
                      size='mini'
              />
            </Grid.Column>
            
            <Grid.Column textAlign='center'>
              <Icon name={panels[1]} size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => openModal(<RateCompetenciesModal />)}
                      secondary
                      content='Complete'
                      size='mini'
              />
            </Grid.Column>

            <Grid.Column textAlign='center'>
              <Icon name={panels[2]} size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => openModal(<RateCompetenciesModal />)}
                      secondary
                      content='Complete'
                      size='mini'
              />
            </Grid.Column>

            <Grid.Column textAlign='center'>
              <Icon name={panels[3]} size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => openModal(<RateCompetenciesModal />)}
                      primary
                      content='Start'
                      size='mini'
              />
            </Grid.Column>

            <Grid.Column textAlign='center'>
              <Icon name={panels[4]} size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => openModal(<RateCompetenciesModal />)}
                      primary
                      content='Start'
                      size='mini'
              />
            </Grid.Column>

            <Grid.Column textAlign='center'>
              <Icon name='check' size='big' style={{marginBottom: 8}}/>
              <br/>
              <Button onClick={() => alert("Completed all, submit back to employee")}
                      positive
                      content='Submit'
                      size='mini'
                      icon='mail'
              />
            </Grid.Column>

          </Grid>
          
        </Grid.Column>
      </Grid>
    </Fragment>
  )
}

export default ManagerAssessmentForReview;
