import React, {useContext} from 'react';
import {Icon, Message} from "semantic-ui-react";
import {RootStoreContext} from "../../../stores/rootStore";


const StartedAssessmentPanel = () => {
  const rootStore = useContext(RootStoreContext);
  
  return (
    <Message icon style={{height: '100%'}}>
      <Icon name='warning circle' color='green' />
      <Message.Content>
        <Message.Header>Your self assessment is due</Message.Header>
        <p>Your self assessment started on 21 April 2020</p>
      </Message.Content>
    </Message>
  )
}

export default StartedAssessmentPanel;
