import React from 'react';
import {FieldRenderProps} from "react-final-form";
import {FormFieldProps, Form, Label} from "semantic-ui-react";

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps {}

const TextInput: React.FC<IProps> =
  ({input, 
    disabled,
     width, 
     type, 
     placeholder, 
     label,
     meta: {touched, error}}) => {
    return (
      <Form.Field error={touched && !!error} type={type} width={width}>
        {label && <label>{label}</label>}
      <input {...input} placeholder={placeholder} disabled={disabled} />
      {touched && error && (
        <Label basic color='red'>
          {error}
        </Label>
      )}
    </Form.Field>
    );

  }

export default TextInput;
