import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, Fragment, useState} from 'react';
import {Form as FinalForm, Field} from 'react-final-form';
import {values} from "mobx";
import {FORM_ERROR} from "final-form";
import {Button, Form, Grid, Header, Icon, Image, Segment, TextArea} from "semantic-ui-react";
import {RootStoreContext} from "../../stores/rootStore";
import * as yup from "yup";
import {validate} from "../../components/utils/customValidators";
import TextAreaInput from "../../components/formfields/TextAreaInput";

const validateSchema = () =>
  yup.object().shape({
    query: yup
      .string()
      .min(20)
      .max(255)
      .required()
  });


const SupportForm = () => {
  const rootStore = useContext(RootStoreContext);
  const {closeModal} = rootStore.modalStore;
  const {user} = rootStore.userStore;

  const [querySent, setQuerySent] = useState(false);

  const SendQuery = () => {
    alert("Support email sent");
    setQuerySent(true);
    closeModal();
  }
  

  return (
    <Fragment>
      <FinalForm 
        onSubmit={(value:any) => console.log(values)}
        validate={values => validate(values, validateSchema())}
        render={({handleSubmit, invalid, pristine,dirtySinceLastSubmit}) => (
        <Form onSubmit={handleSubmit} error>

          {!querySent &&
            <Fragment>
              <Field name='query' component={TextAreaInput} rows={5} placeholder='Your query'/>
  
              <Button positive size='large' floated='right'
                disabled={(invalid && !dirtySinceLastSubmit)}
                onClick={SendQuery} name='Save'>
                <Icon name='envelope' />
                Send
              </Button>
            </Fragment>
          }
          {querySent && 
            <Fragment>Thank you for your query.</Fragment>}
          


        </Form>
        )}
      />
    </Fragment>
  )
}

export default observer(SupportForm);
