import React, {Fragment, useContext} from 'react';
import {CompetencyStatus} from "./CompetencyPanel";
import {Button, Container, Grid, Icon, Label, Progress} from "semantic-ui-react";

import {observer} from "mobx-react-lite";
import RateCompetenciesModal from "../../common/RateCompetenciesModal";
import {RootStoreContext} from "../../../stores/rootStore";
import {UserStatus} from "../../../stores/userStore";
import ViewCompetenciesModal from "../../common/ViewCompetenciesModal";
import ViewManagerCompetenciesModal from "../../common/ViewManagerCompetenciesModal";

const CompetencyStatusPanel:React.FC<{status: CompetencyStatus}> = ({status}) => {

  const rootStore = useContext(RootStoreContext);
  const {openModal} = rootStore.modalStore;
  const {SetAssessmentStatus} = rootStore.userStore;
  
  const startRating = () => {
    openModal(<RateCompetenciesModal/>);
    SetAssessmentStatus(UserStatus.ReadyToSubmit);
  }

  const viewRating = () => {
    openModal(<ViewCompetenciesModal/>);
  }
  const viewDualRating = () => {
    openModal(<ViewManagerCompetenciesModal/>);
  }


  return (
    <Container>
    {status === CompetencyStatus.Start && (
      <Button
        onClick={startRating}
        primary name='Start'>Start</Button>
    )}
    {status === CompetencyStatus.Update && (
      <Button
        onClick={startRating}
        secondary name='Update'>Update</Button>
    )}
    {status === CompetencyStatus.Pending && (
      <Fragment>
        <Grid columns='equal' style={{'marginBottom': 0}}>
          <Grid.Column>
            My assessment
            <Progress value='12' total='20'>
              12/20
            </Progress>
          </Grid.Column>
          <Grid.Column>
            Manager assessment
            <Label basic>
              <Icon name='hourglass two' />
              Pending
            </Label>
          </Grid.Column>
        </Grid>
        <Button
        onClick={viewRating}
        secondary name='Update'>View</Button>
      </Fragment>
      )}
    {status === CompetencyStatus.Complete && (
      <Fragment>
        <Grid columns='equal' style={{'marginBottom': 0}}>
          <Grid.Column>
            My assessment
            <Progress value='12' total='20'>
              12/20
            </Progress>
          </Grid.Column>
          <Grid.Column>
            Manager assessment
            <Progress value='14' total='20'>
              14/20
            </Progress>
          </Grid.Column>
        </Grid>
        <Button
          onClick={viewDualRating}
          secondary name='Update'>View</Button>
      </Fragment>
    )}

    </Container>
  )
}

export default observer(CompetencyStatusPanel);
