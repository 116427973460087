import React, {useContext} from 'react';
import {Button, Icon, Message} from "semantic-ui-react";
import {RootStoreContext} from "../../../stores/rootStore";
import {UserStatus} from "../../../stores/userStore";

const SubmitAssessmentPanel = () => {
  const rootStore = useContext(RootStoreContext);
  const {SetAssessmentStatus} = rootStore.userStore;
  
  return (
    <Message icon style={{height: '100%'}}>
      <Icon name='warning circle' color='green' />
      <Message.Content>
        <Message.Header>Your self assessment is complete</Message.Header>
        <p>Notify your line manager</p>
        <Button positive size='huge'
                onClick={() => SetAssessmentStatus(UserStatus.Completed)}
        >
          <Icon name='mail' />
          Send</Button>
      </Message.Content>
    </Message>
  )
}

export default SubmitAssessmentPanel;
