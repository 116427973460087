import React, {Fragment, useContext, useEffect, useState} from 'react';
import {
  Button,
  Confirm,
  Container,
  Header,
  Icon,
  List,
  Message,
  Popup,
  Rating,
  Segment,
  Table
} from "semantic-ui-react";

import {observer} from "mobx-react-lite";
import SingleCompetencyRating from "./SingleCompetencyRating";
import {RootStoreContext} from "../../stores/rootStore";

const ViewCompetenciesModal = () => {
  const rootStore = useContext(RootStoreContext);
  const {closeModal} = rootStore.modalStore;
  const {showExtraCompetencyInfo, setShowExtraCompetencyInfo} = rootStore.commonStore;
  
  return (
    <Fragment>
      <Segment basic clearing>
        <Header size='huge' textAlign='center'>View your Brand & Consumer Strategy Rating</Header>

        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={8} />
              <Table.HeaderCell>
                Skill level
                <Popup wide trigger={<Icon name='info circle' />}>
                  <Popup.Content>
                    <Header>Skill levels</Header>
                    <List>
                      <List.Item><Rating icon='star' defaultRating={1} maxRating={4} size='small' disabled /> - Basic understanding </List.Item>
                      <List.Item><Rating icon='star' defaultRating={2} maxRating={4} size='small' disabled /> - Can Contribute Actively</List.Item>
                      <List.Item><Rating icon='star' defaultRating={3} maxRating={4} size='small' disabled /> - Can Lead the Process/Skill Area</List.Item>
                      <List.Item><Rating icon='star' defaultRating={4} maxRating={4} size='small' disabled /> - Recognised as an Expert/Coach in the Area</List.Item>
                    </List>
                  </Popup.Content>
                </Popup>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <SingleCompetencyRating competencyName='Brand Purpose' />
            <SingleCompetencyRating competencyName='Brand Assessment' />
            <SingleCompetencyRating competencyName='Brand Foundation' />
            <SingleCompetencyRating competencyName='Portfolio Strategy' />
            <SingleCompetencyRating competencyName='Marketing Planning' />
          </Table.Body>
        </Table>

        <Button positive size='large' floated='right' onClick={closeModal} name='Close'>
          <Icon name='close' />
          Close
        </Button>
      </Segment>
      
    </Fragment>
  )
}

export default observer(ViewCompetenciesModal);
