import React, {useCallback, useEffect, useRef, Fragment} from 'react';
import Chartjs from 'chart.js';

const lineChartConfig = {
  type: 'bar',
  data: {
    labels: ['Brand purpose',
      'Brand assessment',
      'Brand foundations',
      'Portfolio strategy',
      'Marketing planning',
      'Performance analytics',
      'Competitors & market intelligence',
      'Category insights & story development',
      'Marketing spend effectiveness',
      'Food and culinary IQ',
      'Innovation strategy & ideation',
      'Innovation decision testing',
      'Business case development',
      'Concept writing',
      'Briefing R&D',
      'Campaign strategy',
      'Campaign management & execution',
      'Agency briefing & management',
      'Content creation, syndication & mgt',
      'Public relations & earned media',
      'Shopper marketing',
      'Merchandising strategy & execution',
      'Promotion strategy & planning',
      'Pricing strategy',
      'Customer investment & funding'
      ],
    datasets: [
      {
        label: 'Role target',
        backgroundColor: "rgba(194, 126, 23, 0.5)",
        data: [0],
        steppedLine: true,
        pointRadius: 0
      },
      {
        label: 'Average score',
        backgroundColor: "rgba(52, 207, 62, 0.5)",
        data: [0],
        steppedLine: true,
        pointRadius: 0
      },
      {
        label: 'Your score',
        backgroundColor: "rgba(245, 66, 141, 0.5)",
        data: [0],
        steppedLine: true,
        pointRadius: 0,
        hidden: true
      },
      {
        label: 'Manager\'s score',
        backgroundColor: "rgba(52, 55, 207, 0.5)",
        data: [0],
        steppedLine: true,
        pointRadius: 0,
        hidden: true
      }
    ]
  },
  options: {
    elements: {
      line: {
        tension: 0,
        fill: false
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  }
};

const ResultsLineChart = () => {
  const chartRef = useCallback(node => {
    for(let i = 0; i < 25; i++) {
      let userScore = Math.round(Math.random() * 4);
      let managerScore = Math.round(Math.random() * 4);
      let averageScore = userScore + managerScore / 2;
      lineChartConfig.data.datasets[0].data[i] = Math.round(Math.random() * 4);
      lineChartConfig.data.datasets[1].data[i] = averageScore;
      lineChartConfig.data.datasets[2].data[i] = userScore;
      lineChartConfig.data.datasets[3].data[i] = managerScore;
    }
    
    if (node !== null) {
      const newChartInstance = new Chartjs(node, lineChartConfig);
    }
  }, []);

  return (
    <Fragment>
      <canvas ref={chartRef} />
    </Fragment>
  );
};

export default ResultsLineChart;