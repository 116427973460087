import React, {useContext, useState} from 'react';
import {Form as FinalForm, Field} from 'react-final-form';
import {values} from "mobx";
import {FORM_ERROR, setIn} from "final-form";
import {Button, Container, Form, Message, Segment} from "semantic-ui-react";
import {history} from "../../../index";
import {RootStoreContext} from "../../stores/rootStore";
import TextInput from "../../components/formfields/TextInput";
import {validate} from "../../components/utils/customValidators";
import * as yup from 'yup';


const validateSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .max(255)
      .email("Not a valid email address")
      .required()
  });


const LoginForm = () => {
  const [showForm, setShowForm] = useState(false);
  
  const rootStore = useContext(RootStoreContext);
  return (
    <Segment textAlign='left'>
      <FinalForm 
        onSubmit={(value:any) => console.log(values)}
        validate={values => validate(values, validateSchema())}
        render={({handleSubmit, invalid, pristine,dirtySinceLastSubmit}) => (
        <Form onSubmit={handleSubmit} error>
          <Field name='email' component={TextInput} placeholder='Email' label='Email address'/>
          <Button
            primary
            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
            content='Login'
            fluid
            onClick={() => setShowForm(true)}
            />
        </Form>
        )}
      />
      
      {showForm && 
      <Container textAlign='left' style={{marginTop: 20}}>
        <Message success>
          <Message.Header>We have sent you an email</Message.Header>
          <Message.Content>
              If you are a recognised user of this system, a link has been sent to your email address.
              If you have not received an access email within 5 minutes, please check your spam folder.
          </Message.Content>
        </Message>
        <Button primary content='Fake going in from email' onClick={() => history.push('/dashboard')} />
      </Container>}

    </Segment>
  
)
}

export default LoginForm;
