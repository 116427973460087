import React from 'react';
import {Container, Segment, Header} from "semantic-ui-react";
import LoginForm from "../forms/LoginForm";

const LandingPage = () => {
  return (
    <Segment inverted textAlign='center' vertical className='masthead'>
      <Container text>
        <Header inverted size='large'>
          Marketing Skills Assessement Tool
        </Header>
        <LoginForm />
      </Container>
    </Segment>
  )
}

export default LandingPage;
