import {ValidationError} from "yup";
import {setIn} from "final-form";

export const validate = async (values:any, schema:any) => {
  if (typeof schema === 'function')
    schema = schema();

  try {
    await schema.validate(values, { abortEarly: false });
  } catch (e) {
    return e.inner.reduce((errors:any, error:ValidationError) => {
      return setIn(errors, error.path, error.message);
    }, {});
  }
};
