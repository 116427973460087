import {RootStore} from "./rootStore";
import {action, observable} from "mobx";
import {IUserProfile} from "../models/user";

export enum UserStatus {
  Started,
  ReadyToSubmit,
  PendingManagerReview,
  Completed
}

export default class UserStore {
  rootStore: RootStore;
  
  constructor(rootStore:RootStore) {
    this.rootStore = rootStore;
  }
  
  @observable user: IUserProfile = {
    firstName: "Ian",
    lastName: "Flory",
    role: "Senior Technician",
    image: "user-flo.png",
    lineManager:
    {
      name: 'David Bradley-Bird',
      email: 'dbb@multiplyagency.com'
    }
  }
  
  @observable assessmentStatus: UserStatus = UserStatus.Started;
  
  @action SetAssessmentStatus = (status: UserStatus) => {

    if(status === UserStatus.Completed) {
      alert("We're pretending the manager has received and reviewed your ratings now.");
    }

    this.assessmentStatus = status;
  }
}
