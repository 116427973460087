import {configure} from "mobx";
import {createContext} from "react";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import UserStore from "./userStore";

configure({enforceActions: 'always'});

export class RootStore {
  
  commonStore: CommonStore;
  modalStore: ModalStore;
  userStore: UserStore;
  
  constructor() {
    this.commonStore = new CommonStore(this);
    this.modalStore = new ModalStore(this);
    this.userStore = new UserStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());
