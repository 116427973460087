import React from 'react';
import {Button, Icon, Message} from "semantic-ui-react";
import {history} from "../../../../index";

const CompletedAssessmentPanel = () => {
  return (
    <Message icon style={{height: '100%'}}>
      <Icon name='check' color='green' />
      <Message.Content>
        <Message.Header>Assessment has been reviewed</Message.Header>
        <p>Review your results and recommended learning plan</p>
        <Button positive size='huge'
                onClick={() => history.push('/results')}
        >
          <Icon name='chart bar' />
          Results</Button>
      </Message.Content>
    </Message>
  )
}

export default CompletedAssessmentPanel;


