import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Container, Icon, Menu, Dropdown, Image} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import {RootStoreContext} from "../../stores/rootStore";
import {history} from "../../../index";

const NavBar: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {user} = rootStore.userStore;
  
  const LogOut = () => {
    alert("Log out");
    history.push('/');
  }
  
  return (
    <Menu fixed='top' inverted icon='labeled'>
      <Container>
        <Menu.Item header>
          <img src='/assets/multiply-logo.png' alt="Logo" style={{marginTop: 4, marginRight: 20}}/>
        </Menu.Item>

        <Menu.Item
          name='Dashboard'
          header as={NavLink} exact to='/dashboard'
        >
          <Icon name='dashboard' />
          Dashboard
        </Menu.Item>

        <Menu.Item
          name='Results'
          header as={NavLink} exact to='/results'
        >
          <Icon name='chart bar' />
          Results
        </Menu.Item>

        <Menu.Item
          name='Manage'
          header as={NavLink} exact to='/manager-dashboard'
        >
          <Icon name='tasks' />
          Manage
        </Menu.Item>
        
        <Menu.Item
          name='Support'
          header as={NavLink} exact to='/support'
        >
          <Icon name='help' />
          Support
        </Menu.Item>

        <Menu.Item position='right'>
          <Image avatar spaced='right' src={'/assets/' + user.image} style={{marginBottom:6}} />
          <Dropdown pointing='top left' text='Ian Flory'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={LogOut} text='Logout' icon='power' />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>


      </Container>
    </Menu>
  )
}

export default observer(NavBar);
